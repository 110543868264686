import React, { useEffect, useState } from "react";
import { baseUrl, imageUrl } from "../Constants/Constants";
import axios from "axios";
import "./Home.css";
import { purple, yellow, green, blue, red, orange } from "@mui/material/colors";
import ImageSlider from "../ImageSlider/ImageSlider";
import {
  CircularProgress,
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  TextField,
  IconButton,
  Alert,
} from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import GrassIcon from "@mui/icons-material/Grass";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import EastIcon from "@mui/icons-material/East";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Link } from "react-router-dom";
import freshVeg from "../Assets/freshVegetables.png";
import farmerImage from "../Assets/farmer.jpg";
// import boxImage1 from "../Assets/ooty-crops-box.png";
import boxImage2 from "../Assets/ooty-crops-box1.png";
import ProductCard from "../ProductCard/ProductCard";
import { Helmet } from "react-helmet-async";

function Home() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [products, setProducts] = useState([]);
  const [categories, setCategoies] = useState([]);
  const [updatedOn, setUpdatedOn] = useState();

  const [subscriber, setSubscriber] = useState(null);
  const [subscriberRes, setSubscriberRes] = useState(null);
  const [subscriberErr, setSubscriberErr] = useState(null);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    await axios
      .get(baseUrl + "/f_home")
      .then((response) => {
        setProducts(response.data.products);
        setCategoies(response.data.categories);
        setUpdatedOn(response.data.updatedOn);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.data.response.message);
        setLoading(false);
      });
  };

  const addSubscriber = async () => {
    if (subscriberErr !== null) {
      setSubscriberErr(null);
    }

    if (subscriber === null) {
      return setSubscriberErr("Please enter email");
    }

    const formData = new FormData();
    formData.append("email", subscriber);

    try {
      const res = await axios.post(baseUrl + "/add_subscriber", formData);
      setSubscriberRes(res.data.message);
    } catch (error) {
      const errMsg = error.response.data.message || "Subscription failed";
      setSubscriberErr(errMsg);
    }
  };

  return (
    <>
      <Helmet>
        <title>Ooty Crops | Fresh Ooty Vegetables</title>
        <meta
          name="description"
          content="Fresh Exotic and English vegetables from Ooty"
        />
      </Helmet>
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <span className="text-danger">Error </span>: Something went wrong. Try
          again later.
        </Box>
      )}
      <ImageSlider />
      {/* Icons grid */}
      <Grid container spacing={4} sx={{ textAlign: "center", py: 4 }}>
        <Grid item xs={6} sm={3}>
          <Box>
            <LocalShippingIcon
              sx={{
                fontSize: "86px",
                background: purple[400],
                padding: "12px",
                borderRadius: "50%",
                color: "#fff",
              }}
            />
            <Typography sx={{ mt: 1 }}>Shipping all over India</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Box>
            <GrassIcon
              sx={{
                fontSize: "86px",
                background: green[400],
                padding: "12px",
                borderRadius: "50%",
                color: "#fff",
              }}
            />
            <Typography sx={{ mt: 1 }}>Always fresh</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Box>
            <WorkspacePremiumIcon
              sx={{
                fontSize: "86px",
                background: blue[400],
                padding: "12px",
                borderRadius: "50%",
                color: "#fff",
              }}
            />
            <Typography sx={{ mt: 1 }}>Superier quality</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Box>
            <SupportAgentIcon
              sx={{
                fontSize: "86px",
                background: yellow[800],
                padding: "12px",
                borderRadius: "50%",
                color: "#fff",
              }}
            />
            <Typography sx={{ mt: 1 }}>24/7 Customer support</Typography>
          </Box>
        </Grid>
      </Grid>
      {/* Introduction box */}
      <div
        className="introBox"
        // initial={{ opacity: 0, x: -400 }}
        // whileInView={{
        //   opacity: 1,
        //   x: 0,
        //   transition: {
        //     ease: "easeIn",
        //   },
        // }}
      >
        <p style={{ color: "#fff" }} className="introtext h1 fw-bold">
          Elevate Your Produce Aisle: Vegetables from Ooty Crops
        </p>
        <p className="mt-3" style={{ color: "#fff" }}>
          Searching for premium vegetables that will keep your customers coming
          back for more? <br />
          Look no further than Ooty Crops! We're a premier supplier of the
          finest, hand-picked vegetables sourced directly from the rich, fertile
          slopes of Ooty. Our commitment to freshness and quality ensures
          vibrant, delicious produce that elevates any dish. Explore our
          extensive selection and discover the Ooty Crops difference!
        </p>

        <div className="text-center">
          <Button
            variant="contained"
            component={Link}
            to={
              "https://wa.me/+918344533325?text=I%20am%20interested%20in%20your%20products"
            }
            sx={{
              backgroundColor: orange[700],
              borderRadius: "25px",
              ":hover": {
                backgroundColor: "#fff",
                color: "#ff6d00",
              },
            }}
          >
            Contact us
          </Button>
        </div>

        <p className="mt-3 text-white">
          Or call us at <b>+91 83445 33325</b>
        </p>
      </div>
      {/* Category grid */}
      {categories.length > 0 && (
        <>
          <p
            className="h3 mb-4 text-success text-center"
            style={{ marginTop: "5rem" }}
          >
            Browse by category
          </p>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            {categories.map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <Link
                  style={{ textDecoration: "none" }}
                  to="/category"
                  state={{ id: item.id, category: item.category_name }}
                >
                  <Card>
                    <CardMedia
                      image={imageUrl + item.image}
                      alt={item.category_name}
                      sx={{ height: 200 }}
                      className="product-image"
                    />
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>{item.category_name}</Typography>
                        <EastIcon color="info" />
                      </Box>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </>
      )}
      {products.length > 0 && (
        <>
          <p
            className="h3 mb-4 text-success text-center"
            style={{ marginTop: "5rem" }}
          >
            Browse all products
          </p>

          <Alert
            icon={false}
            severity="error"
            sx={{ mb: 4, display: "block", textAlign: "center" }}
          >
            <span className="text-primary">Rates updated on {updatedOn}</span>
            <br /> Rates may vary depending on quality
            <br /> Rates given for first quality products
          </Alert>

          {/* Products grid */}

          <Grid container spacing={2}>
            {products.map((item) => (
              <Grid item xs={6} sm={4} md={2.4} key={item.id}>
                <ProductCard product={item} />
              </Grid>
            ))}

            <Grid
              item
              xs={12}
              sx={{
                mt: 3,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                component={Link}
                to="/allProducts"
                variant="contained"
                sx={{
                  width: "50%",
                  "@media (max-width: 768px)": {
                    width: "100%",
                  },
                }}
              >
                View All
              </Button>
            </Grid>
          </Grid>
        </>
      )}

      {/* Quality and freshness grid */}
      <Grid container spacing={6} sx={{ my: 6 }}>
        <Grid item xs={12} sm={6}>
          <h1 className="text-success mb-4 fw-bold introtext">
            Nature's Bounty Delivered: Ooty Crops, Where Freshness Reigns
            Supreme
          </h1>

          <p style={{ fontSize: "18px", textAlign: "justify" }}>
            Indulge in the purest flavor nature has to offer with Ooty Crops.
            Nestled amidst the pristine hills of Ooty, our partner farms
            cultivate the finest vegetables using traditional methods that
            prioritize quality over quantity. We meticulously handpick each
            vegetable at the peak of ripeness, ensuring it arrives at your
            doorstep bursting with its inherent goodness. From the cool mountain
            air to your kitchen, Ooty Crops delivers an unparalleled freshness
            experience, unmatched by any other.
          </p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={freshVeg}
            alt="Fresh ooty vegetables"
            className="freshVeg"
          />
        </Grid>
      </Grid>
      {/* Packing section grid */}
      <Grid container spacing={6} sx={{ my: 6 }}>
        <Grid
          item
          xs={12}
          sm={6}
          order={{ xs: 2, sm: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={boxImage2} alt="Fresh ooty vegetables" height={"400px"} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          order={{ xs: 1, sm: 2 }}
        >
          <h1 className="text-success mb-4 fw-bold introtext">
            Peak Freshness, Protected Journey: Our Commitment to Secure Packing
          </h1>

          <p style={{ fontSize: "18px", textAlign: "justify" }}>
            At Ooty Crops, we understand the importance of your precious produce
            arriving fresh and vibrant. That's why we prioritize meticulous
            packing, using eco-friendly materials that are both gentle on the
            environment and effective in safeguarding your vegetables. Our
            meticulously crafted boxes and innovative insulation techniques
            ensure your order travels securely from the pristine hills of Ooty
            to your doorstep, preserving the peak freshness and flavor that
            defines Ooty Crops.
          </p>
        </Grid>
      </Grid>
      {/* Famers partnership grid */}
      <Grid container spacing={6} sx={{ my: 6 }}>
        <Grid item xs={12} sm={6}>
          <h1 className="text-success mb-4 fw-bold introtext">
            Supporting Local, Empowering Farmers: A Sustainable Partnership
          </h1>

          <p style={{ fontSize: "18px", textAlign: "justify" }}>
            At Ooty Crops, we're committed to more than just delivering fresh
            produce. We foster a collaborative relationship with local Ooty
            farmers, built on mutual respect and shared values. By working
            directly with our growers, we ensure fair compensation, promoting
            sustainable farming practices and empowering the communities that
            cultivate our high-quality vegetables. This partnership allows us to
            source the finest seasonal ingredients while contributing to the
            economic well-being of the Ooty region. Choose Ooty Crops - where
            freshness meets responsibility.
          </p>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={farmerImage}
            alt="Framer Partner"
            style={{
              width: "100%",
              objectFit: "cover",
              borderRadius: "8px",
            }}
          />
        </Grid>
      </Grid>
      {/* Join Us section */}
      <Box
        sx={{
          borderRadius: "8px",
          background: "#005a38",
          py: 5,
          px: 3,
          my: 5,
          textAlign: "center",
        }}
      >
        <Grid
          container
          spacing={2}
          rowGap={2}
          sx={{ color: "#fff", alignItems: "center", justifyContent: "center" }}
        >
          <Grid
            item
            xs={12}
            sm={3}
            color={yellow[700]}
            sx={{ fontSize: "64px", fontWeight: "bold", fontFamily: "Signika" }}
          >
            Join Us
          </Grid>
          <Grid item xs={12} sm={3}>
            <div className="fw-bold" style={{ fontSize: "64px" }}>
              30+
            </div>
            <p>Years Experience</p>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div className="fw-bold" style={{ fontSize: "64px" }}>
              1000+
            </div>
            <p>Happy Customers</p>
          </Grid>
          <Grid item xs={12} sm={3}>
            <p>Subscribe for daily rates</p>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              sx={{ background: "#fff", borderRadius: "6px", mb: 2 }}
              placeholder="Enter your e-mail"
              onChange={(e) => {
                setSubscriber(e.target.value);
              }}
            />
            <Button
              variant="outlined"
              className="text-light border-light fw-bold mb-2"
              onClick={addSubscriber}
            >
              Subscribe
            </Button>
            {subscriberRes && (
              <p style={{ color: yellow[700] }}>{subscriberRes}</p>
            )}
            {subscriberErr && (
              <p style={{ color: red[400] }}>{subscriberErr}</p>
            )}
          </Grid>
        </Grid>

        <Link to="https://wa.me/+918344533325?text=I'm%20interested%20in%20your%20products">
          <div
            className="mb-2 d-block d-md-block"
            style={{
              borderRadius: "50%",
              position: "fixed",
              bottom: "20px",
              right: "12px",
              background: "#25D366",
              padding: "2px",
            }}
          >
            <IconButton size="large" style={{ color: "white" }}>
              <WhatsAppIcon fontSize="large" />
            </IconButton>
          </div>
        </Link>
      </Box>
    </>
  );
}

export default Home;
